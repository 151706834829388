.custom-color {
  background-color: #5664d2 !important;
  color: #fff !important;

  &:hover {
    opacity: 0.8;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.hover-container:hover {
  cursor: pointer;
}
